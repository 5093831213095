import axios from 'axios'
import Bus from '@/assets/js/vueBus'
import toTipsObj from './toTipsObj.js'
import Vue from 'vue'
// import $store from '@/store.js'
window.utilVue = new Vue()
let Tool = {
  getType (value) {
    return Object.prototype.toString.call(value).slice(8, -1)
  },
  formatToDateTime (time, formats) {
    if (time && time > 0) {
      return dayjs(time).format(formats)
    } else {
      return '---'
    }
  },
  changeTime (date) {
    if (date != null) {
      let time = new Date(date * 1000)
      Date.prototype.toLocaleString = function () {
        return (
          this.getFullYear() +
          '/' +
          (this.getMonth() + 1) +
          '/' +
          this.getDate() +
          '/ ' +
          this.getHours() +
          ':' +
          this.getMinutes() +
          ':' +
          this.getSeconds()
        )
      }
      return time.toLocaleString()
    } else {
      return null
    }
  },
  changeDateToStr (ts, index) {
    let dateVal = this.changeDate(ts, 0)
    let newDate = ''
    if (index == 'e') {
      newDate = dateVal + ' 23:59:59'
    } else {
      newDate = dateVal + ' 00:00:00'
    }
    return new Date(newDate).getTime()
  },
  changeDate (ts, num) {
    const oneDay = 1000 * 60 * 60 * 24
    let dateStr = new Date(ts + oneDay * num - 0)
    let y = dateStr.getFullYear()
    let mon = dateStr.getMonth() + 1
    let d = dateStr.getDate()
    return mon + '/' + d + '/' + y
  },
  MillisecondToDate (msd) {
    // 获取时分秒 msd 单位:s
    var time = parseFloat(msd)
    if (time != null && time != '') {
      if (time > 60 && time < 60 * 60) {
        time =
          '00:' +
          parseInt(time / 60.0) +
          ':' +
          parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60)
      } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
        time =
          parseInt(time / 3600.0) +
          ':' +
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
          ':' +
          parseInt(
            (parseFloat(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) -
              parseInt(
                (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
              )) *
              60
          )
      } else {
        time = '00:00:' + parseInt(time)
      }
    }
    return time
  },
  changeNewTime (ts) {
    var dateStr = new Date(ts - 0) // 1453161600000
    var h = dateStr.getHours()
    h = h < 10 ? '0' + h : h
    var min = dateStr.getMinutes()
    min = min < 10 ? '0' + min : min
    var s = dateStr.getSeconds()
    s = s < 10 ? '0' + s : s
    return h + ':' + min + ':' + s
  },
  combineDateTime (dateTimestamp, timeTimestamp) {
    const dateObj = new Date(dateTimestamp) // 创建日期对象
    const timeObj = new Date(timeTimestamp) // 创建时间对象

    dateObj.setHours(timeObj.getHours()) // 设置小时
    dateObj.setMinutes(timeObj.getMinutes()) // 设置分钟
    dateObj.setSeconds(timeObj.getSeconds()) // 设置秒钟

    return dateObj.getTime() // 返回拼接后的时间戳
  },
  isValidTimestamp (timestampString) {
    if (!timestampString) return false
    const timestamp = Number(timestampString) // 尝试将字符串转换为数字类型的时间戳
    if (isNaN(timestamp)) {
      return false // 转换失败，字符串不是有效的时间戳
    }
    const dateObj = new Date(timestamp) // 创建日期对象
    // 检查日期对象是否有效
    if (dateObj.toString() === 'Invalid Date') {
      return false // 日期对象无效，字符串不是有效的时间戳
    }
    return true // 字符串是有效的时间戳
  },
  compareTimeStamps (timestamp1, timestamp2) {
    const date1 = new Date(timestamp1)
    const date2 = new Date(timestamp2)

    // 获取时间戳的时分秒部分
    const hours1 = date1.getHours()
    const minutes1 = date1.getMinutes()
    const seconds1 = date1.getSeconds()

    const hours2 = date2.getHours()
    const minutes2 = date2.getMinutes()
    const seconds2 = date2.getSeconds()

    // 比较时分秒部分
    if (hours1 === hours2 && minutes1 === minutes2 && seconds1 === seconds2) {
      return 0 // 时间戳相等
    } else if (hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2) || (hours1 === hours2 && minutes1 === minutes2 && seconds1 < seconds2)) {
      return -1 // timestamp1 < timestamp2
    } else {
      return 1 // timestamp1 > timestamp2
    }
  },
  CompareTime (timeList) {
    let newTimeList = timeList.sort(this.compareDate)
    return newTimeList
  },
  tagList (array) {
    let str = ''
    if (array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        str += array[i] + ','
      }
    }
    return str.substring(0, str.length - 1)
  },
  size (data, key) {
    switch (key) {
      case 'MB':
        return (data / 1024 / 1024).toFixed(1) + 'MB'
      case 'GB':
        return (data / 1024 / 1024 / 1024).toFixed(1) + 'GB'
    }
  },
  returnFindIndex (str, cha, num) {
    var x = str.indexOf(cha)
    for (var i = 1; i < num; i++) {
      x = str.indexOf(cha, x + 1)
    }
    return x + 1
  },
  compareDate (item1, item2) {
    const time1 = item1.StartTimeStamp
    const time2 = item2.StartTimeStamp
    if (time1 < time2) {
      return -1
    } else if (time1 > time2) {
      return 1
    } else {
      return 0
    }
  },
  changeArray (options) {
    let returnArray = []
    const array = options.data
    const itemType = Array.isArray(array)
    const isSame = options.isSame
    const valArray = options.valArray || ['value', 'label']
    const keyArray = options.keyArray || ['id', 'name']
    // 先判断数据是数组还是对象
    if (itemType) {
      // 判断数组里面的值是string还是object,分别处理.
      const dataType = typeof array[0]
      if (dataType === 'string') {
        // 判断是否生成值一样的数组
        if (isSame) {
          // 生成结构(例如value,label):[{value:'Thomas',label:'Thomas'}]
          array.map((item, index) => {
            var obj = {}
            valArray.map((itm, idx) => {
              obj[itm] = item
            })
            returnArray.push(obj)
          })
        } else {
          // 生成结构[{value:1,label:'Thomas'}],注意此方法不支持更改valArray里面的值。
          array.map((item, index) => {
            var obj = {}
            valArray.map((itm, idx) => {
              itm === 'value' ? (obj[itm] = index) : (obj[itm] = item)
            })
            returnArray.push(obj)
          })
        }
      } else {
        if (isSame) {
          array.map((item, index) => {
            var obj = {}
            valArray.map((itm, idx) => {
              obj[itm] = item[keyArray[0]]
            })
            returnArray.push(obj)
          })
        } else {
          array.map((item, index) => {
            var obj = {}
            valArray.map((itm, idx) => {
              obj[itm] = item[keyArray[idx]]
            })
            returnArray.push(obj)
          })
        }
      }
    }
    return returnArray
  },
  // 返回32位随机水
  return32Str (length = 32) {
    var chars = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z'
    ]
    var num = ''
    for (var i = 0; i < length; i++) {
      let id = parseInt(Math.random() * 61)
      num += chars[id]
    }
    return num
  },
  axios (method, url, data, callback) {
    axios({
      method: method,
      url: url,
      data: data || ''
    }).then(res => {
      if (res.status == 200) {
        if (res.data.errorCode) {
          if (res.data.errorCode == '0x0') {
            res.data.result ? callback(res.data.result) : callback(res.data)
          }
        } else {
          callback(res.data)
        }
      }
    }).catch(() => {})
  },
  getHashParams (key) {
    const hashVal = window.location.hash.split('?')[1]
    if (!hashVal) return
    const hashArr = hashVal.split('&')
    for (let i = 0; i < hashArr.length; i++) {
      if (hashArr[i].split('=')[0] == key) {
        return hashArr[i].split('=')[1] || false
      }
    }
    return false
  },
  // 获取session
  async getSession () {
    let session = ''
    await axios.get('/ccp/tvucc-user/base/getSessionId').then(res => {
      if (res.data.errorCode == '0x0' && res.data.result) {
        session = res.data.result
      }
    }).catch(() => { })
    if (!session) {
      Bus.$emit('jumpLoginPage')
      return
    }
    window.localStorage.setItem('NewCCsession', session)
    return session
  },
  getQueryString (name, str, symbol) {
    str = str == null ? window.location.search.substr(1) : str
    symbol = symbol == null ? '&' : symbol
    var reg = new RegExp('(^|' + symbol + ')' + name + '=([^' + symbol + ']*)(' + symbol + '|$)', 'i')
    var r = str.match(reg)
    if (r != null) return unescape(r[2])
    return null
  },
  getUTCTime (date) {
    // 返回UTC时间
    return date.getTime() + date.getTimezoneOffset() * 60000
  },
  timeFormat (time) {
    return time < 10 ? `0${time}` : time
  },
  getDateTime (time) {
    time = time || new Date()
    var year = time.getFullYear()
    var month = this.timeFormat(time.getMonth() + 1)
    var day = this.timeFormat(time.getDate())
    var hour = this.timeFormat(time.getHours())
    var min = this.timeFormat(time.getMinutes())
    var s = this.timeFormat(time.getSeconds())
    return { year: year, month: month, day: day, hour: hour, min: min, s: s }
  },
  getUrl (url) {
    return '/ccp/tvucc-booking' + url
    // return '/tvucc' + url;
  },
  // 时间格式
  DATE (date, fmt) {
    // 传入时间戳
    if (!date) {
      return ''
    }
    date = new Date(date * 1)
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
        )
      }
    }
    return fmt
  },
  /* 判断是否是内网IP */
  isInnerIPFn () {
    // 获取当前页面url
    var curPageUrl = window.location.href
    var reg1 = /(http|ftp|https|www):\/\//g // 去掉前缀
    curPageUrl = curPageUrl.replace(reg1, '')
    // console.log('curPageUrl-1  '+curPageUrl);

    var reg2 = /\:+/g // 替换冒号为一点
    curPageUrl = curPageUrl.replace(reg2, '.')
    // console.log('curPageUrl-2  '+curPageUrl);

    curPageUrl = curPageUrl.split('.') // 通过一点来划分数组
    console.log(curPageUrl)

    var ipAddress =
      curPageUrl[0] +
      '.' +
      curPageUrl[1] +
      '.' +
      curPageUrl[2] +
      '.' +
      curPageUrl[3]

    var isInnerIp = false // 默认给定IP不是内网IP
    var ipNum = this.getIpNum(ipAddress)
    /**
     * 私有IP：A类  10.0.0.0    -10.255.255.255
     *       B类  172.16.0.0  -172.31.255.255
     *       C类  192.168.0.0 -192.168.255.255
     *       D类   127.0.0.0   -127.255.255.255(环回地址)
     *
     */
    var aBegin = this.getIpNum('10.0.0.0')
    var aEnd = this.getIpNum('10.255.255.255')
    var bBegin = this.getIpNum('172.16.0.0')
    var bEnd = this.getIpNum('172.31.255.255')
    var cBegin = this.getIpNum('192.168.0.0')
    var cEnd = this.getIpNum('192.168.255.255')
    var dBegin = this.getIpNum('127.0.0.0')
    var dEnd = this.getIpNum('127.255.255.255')
    isInnerIp =
      this.isInner(ipNum, aBegin, aEnd) ||
      this.isInner(ipNum, bBegin, bEnd) ||
      this.isInner(ipNum, cBegin, cEnd) ||
      this.isInner(ipNum, dBegin, dEnd)
    console.log('是否是内网:' + isInnerIp)
    return isInnerIp
  },
  getIpNum (ipAddress) {
    /* 获取IP数 */
    var ip = ipAddress.split('.')
    var a = parseInt(ip[0])
    var b = parseInt(ip[1])
    var c = parseInt(ip[2])
    var d = parseInt(ip[3])
    var ipNum = a * 256 * 256 * 256 + b * 256 * 256 + c * 256 + d
    return ipNum
  },
  isInner (userIp, begin, end) {
    return userIp >= begin && userIp <= end
  },
  getCookie (name) {
    let [arr, reg] = ['', new RegExp('(^| )' + name + '=([^;]*)(;|$)')]
    arr = document.cookie.match(reg)
    // return arr?unescape(arr[2]):'en-US';
    return arr ? unescape(arr[2]) : 'en'
  },
  dynamicLoadJs (url, callback, id) {
    var head = document.getElementsByTagName('body')[0]
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = id || ''
    script.src = url
    if (typeof callback == 'function') {
      script.onerror = () => {
        let status = 'failed'
        callback && callback(status)
      }
      script.onload = () => {
        if (
          !this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete'
        ) {
          let status = 'true'
          callback && callback(status)
          script.onload = script.onreadystatechange = null
        }
      }
    }
    head.appendChild(script)
  },

  includeLinkStyle (url) {
    var link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = url
    document.getElementsByTagName('head')[0].appendChild(link)
  },
  /*
   * 时间转换
   * 2017 ===> 2017-12-31 23:59:59;
   * 2017-5 ===> 2017-5-31 23:59:59
   * timeString: 时间字符串
   * isStart: 是否开始时间，否,则是结束时间
   * separator: 字符串分隔符
   */

  formatTime (timeString, isStart, separat) {
    if (!timeString) return
    var arr = []
    var target = 0
    var monthNum = ''
    var separator = ''

    separator = separat || '-'

    arr = timeString.split(separator)
    if (arr.length === 1) {
      // 年：2017
      if (isStart) {
        // 开始时间
        timeString = timeString + '/01/01 00:00:00'
      } else {
        // 结束时间
        timeString = timeString + '/12/31 23:59:59'
      }
    } else if (arr.length === 2) {
      // 年月:2017-05
      if (isStart) {
        timeString = timeString + separator + '01 00:00:00'
      } else {
        monthNum = new Date(arr[0], arr[1], 0).getDate() + ''
        timeString = timeString + separator + monthNum + ' 23:59:59'
      }
    } else if (arr.length === 3) {
      // 年月日:2017-05-25
      if (isStart) {
        timeString = timeString + ' 00:00:00'
      } else {
        timeString = timeString + ' 23:59:59'
      }
    } else {
      return false
    }
    target = new Date(timeString).getTime()
    return target
  },
  // 加密
  encryption (pid) {
    let index = Math.floor(pid / 2)
    let preNum = pid.slice(0, index)
    let afternum = pid.slice(index, 16)
    return window.btoa(afternum + '_' + preNum)
  },
  basePeerId (pid) {
    let firstPart = pid.substring(0, 8).toLowerCase()
    let secondPart = pid.substring(8, 16).toLowerCase()
    let firstPartDec = parseInt(firstPart, 16)
    let secondPartDec = parseInt(secondPart, 16)
    let combined = `${secondPartDec}_${firstPartDec}`
    return Buffer.from(combined).toString('base64')
  },
  // 转换参数
  TransferParams (params) {
    let str = '?'
    for (let key in params) {
      str += key + '=' + params[key] + '&'
    }
    return str.slice(0, str.length - 1)
  },
  ToTip (errorCode, type, deafaultTip) {
    // 提示方法
    /*
     *  lang=>对应的中英文翻译
     *  type=>提示类型
     *  errorCode=>错误代码
     *  deafaultTip=>默认的其它的提示或在此方法内找不到对应code的提示
     *
     */
    let messageText = toTipsObj[errorCode] ? toTipsObj[errorCode] : deafaultTip
    let warningType = type || 'warning'
    if (!messageText) {
      // 所有的提示都没有给
      messageText = 'lang.errorTips'
      console.log("Don't give tips key => 没有给提示的关键字")
    }
    messageText = i18n.t(messageText)
    window.utilVue.$message({
      message: messageText,
      type: warningType
    })
  },
  loadMapJs (lang, fn, tag) {
    if (lang == 'zh') {
      if (tag && tag == 'markercluster') {
        window.baiduMapCb = () => {
          this.dynamicLoadJs('./reference/TextIconOverlay.js', status1 => {
            if (status1 == 'true') {
              this.dynamicLoadJs('./reference/baiduMarkerclusterer.js', status2 => {
                if (status2 == 'true') {
                  fn()
                } else {
                  window.utilVue.$message({
                    type: 'info',
                    message: i18n.t('lang.i18n_mapFailed')
                  })
                }
              })
            } else {
              window.utilVue.$message({
                type: 'info',
                message: i18n.t('lang.i18n_mapFailed')
              })
            }
          })
        }
      } else {
        window.baiduMapCb = fn
      }
      let protocol = window.location.protocol
      let mapKey = (localStorage.getItem('newCCInitConfig') && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.baiduMapAccount) || 'dW8YB7KbQCedj4BqmjnL0mZP'
      if ($('#baiduMap').length > 0) {
        window.baiduMapCb()
      } else {
        this.dynamicLoadJs(
          `${protocol}//api.map.baidu.com/api?v=3.0&ak=${mapKey}&callback=baiduMapCb`, status => {
            console.log(status)
          },
          'baiduMap'
        )
      }
    } else {
      let protocol = window.location.protocol
      let loadZh = !!lang.includes('zh')
      let mapKey = (localStorage.getItem('newCCInitConfig') && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.googleMapAccount) || 'AIzaSyAmVG66-xSKTHviKU_9y42KsHNlt_jI3X4'
      // mapKey = "AIzaSyAmVG66-xSKTHviKU_9y42KsHNlt_jI3X4",
      let libraries = 'places'
      let cnJs = `${protocol}//maps.google.cn/maps/api/js?key=${mapKey}&libraries=${libraries}&language=cn`
      let enJs = `${protocol}//maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=${libraries}&language=en`
      let keyUrl = loadZh ? cnJs : enJs
      let otherUrl = loadZh ? enJs : cnJs
      let keyId = loadZh ? 'googlemap_cnMap' : 'googlemap_enMap'
      let otherId = loadZh ? 'googlemap_enMap' : 'googlemap_cnMap'
      const labelId = 'googlemap_labelJs'
      const labelJsUrl = './reference/markerwithlabel.js'
      if (($('#googlemap_cnMap').length > 0 && loadZh) || ($('#googlemap_enMap').length > 0 && !loadZh)) {
        fn()
      } else if (($('#googlemap_cnMap').length > 0 && !loadZh) || ($('#googlemap_enMap').length > 0 && loadZh)) {
        history.go(0)
      } else {
        this.dynamicLoadJs(
          keyUrl,
          status => {
            if (status != 'true') {
              this.dynamicLoadJs(
                otherUrl,
                status => {
                  if (status != 'true') {
                    window.utilVue.$message({
                      type: 'info',
                      message: i18n.t('lang.i18n_mapFailed')
                    })
                  } else {
                    fn()
                  }
                },
                otherId
              )
            } else {
              this.dynamicLoadJs(
                labelJsUrl,
                () => {
                  fn()
                },
                labelId
              )
            }
          },
          keyId
        )
        if (tag && tag == 'markercluster') {
          this.dynamicLoadJs('./reference/markerclusterer.js', status => {
            console.log(status)
          })
        }
      }
    }
  },
  setPermissionTips (tipTag, langKey) {
    let ccTipObj = {
      mv: {
        zhName:
          '请联系技术支持人员购买此功能，然后你就可以监控并与任意设备通话了。',
        enName:
          'Currently you are not licensed for this feature, please contact support for more information.',
        ruName:
          'В данный момент у вас нет лицензии для использования этой функции. Для дополнительной информации, пожалуйста свяжитесь с технической поддержкой.'
      },
      bk: {
        zhName: '请联系技术支持开通有权限。',
        enName: 'Please contact support for authorization.',
        ruName:
          'Для авторизации, пожалуйста, свяжитесь с тезнической поддержкой.'
      },
      tk: {
        zhName:
          '请联系技术支持人员购买此功能，然后你就可以用TVU直播手机扫描或输入配对码与收发机完成配对。',
        enName:
          'Currently you are not licensed for this feature, please contact support for more information.',
        ruName:
          'В данный момент у вас нет лицензии для использования этой функции. Для дополнительной информации, пожалуйста свяжитесь с технической поддержкой.'
      },
      sh: {
        zhName:
          '请联系技术支持人员购买此功能，然后你就可以在社交媒体上分享视频了。',
        enName:
          'Currently you are not licensed for this feature, please contact support for more information.',
        ruName:
          'В данный момент у вас нет лицензии для использования этой функции. Для дополнительной информации, пожалуйста свяжитесь с технической поддержкой.'
      }
    }
    langKey = langKey || 'enName'
    let tipText = ccTipObj[tipTag]
      ? ccTipObj[tipTag][langKey]
      : ccTipObj.bk[langKey]
    if ($(window).width() > 1024) {
      if (
        $('#headerNoAuthorityBg').length > 0 ||
        $('#headerNoAuthorityTip').length > 0
      ) {
        $('#headerNoAuthorityBg,#headerNoAuthorityTip').remove()
        if (window.tipTimers) {
          clearTimeout(window.tipTimers)
        }
      }
      let permissionObj = `<div id="headerNoAuthorityBg"></div><div id="headerNoAuthorityTip"><p>${tipText}</p></div>`
      $('body').append(permissionObj)
      window.tipTimers = setTimeout(() => {
        if (
          $('#headerNoAuthorityBg').length > 0 ||
          $('#headerNoAuthorityTip').length > 0
        ) {
          $('#headerNoAuthorityBg,#headerNoAuthorityTip').remove()
          clearTimeout(window.tipTimers)
        }
      }, 5000)
      $('#headerNoAuthorityBg').click(() => {
        $('#headerNoAuthorityBg,#headerNoAuthorityTip').remove()
        if (window.tipTimers) {
          clearTimeout(window.tipTimers)
        }
      })
    } else {
      this.$message({
        type: 'info',
        message: tipText
      })
    }
  },
  // js模拟重载
  heavyLoad (obj, key, paramLength, fn, that) {
    obj[`${key}${paramLength}`] = fn
    obj[key] = function () {
      obj[`${key}${arguments.length}`].apply(that, arguments)
    }
  },
  // 获取间隔时间
  getIntervalTime (start, end) {
    start = typeof start == 'object' ? start.getTime() : start
    end = typeof end == 'object' ? end.getTime() : end
    let interval = end - start
    if (interval <= 0) {
      return '00:00:00'
    }
    let d = Math.floor(interval / (1000 * 60 * 60 * 24))
    let h = Math.floor((interval / (1000 * 60 * 60)) % 24) + d * 24
    let m = Math.floor((interval / (1000 * 60)) % 60)
    let s = Math.floor((interval / 1000) % 60)
    return `${this.timeFormat(h)}:${this.timeFormat(m)}:${this.timeFormat(s)}`
  },
  // 禁止输入特殊字符
  cleanSpelChar (val) {
    if (/["'<>%;)(&+]/.test(val)) {
      val = val.replace(/["'<>%;)(&+]/, '')
    }
    return val
  },
  // 百度gps坐标批量转换，points:待转换的坐标数组，call：转换完成后的回调
  tranlatePoint (points, call) {
    var tempArr = [] // 转换完成后的坐标数组
    var total = 0 // 总记录数
    var groupCount = 0 // 每次转十条
    if (points.length % 10 > 0) {
      groupCount = Math.floor(points.length / 10) + 1
    } else {
      groupCount = points.length / 10
    }
    for (let i = 0; i < groupCount; i++) {
      // 外层循环，有多少组十条
      var pos = []
      for (var j = 0; j < 10; j++) {
        // 内层循环，每组十条
        if (total < points.length) {
          // 不超过总记录数结束
          var point = new BMap.Point(
            points[i * 10 + j].lng,
            points[i * 10 + j].lat
          )
          pos.push(point)
        }
        total++
      }
      var convertor = new BMap.Convertor()
      convertor.translate(pos, 3, 5, function (data) {
        if (data.status === 0) {
          for (var k = 0; k < data.points.length; k++) {
            tempArr.push(data.points[k])
          }
          if (i == groupCount - 1) {
            call(tempArr)
          }
        }
      })
    }
  },
  clearTSID () {
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    const domainName = `.${str1}.${str2}`
    let exp = new Date() // 获取客户端本地当前系统时间
    exp.setTime(exp.getTime() - 60 * 1000) // 将exp设置为客户端本地时间1分钟以前，将exp赋值给cookie作为过期时间后，就表示该cookie已经过期了, 那么浏览器就会将其立刻删除掉
    document.cookie = `${['TSID', '=', ''].join(
      ''
    )}; path=/;domain=${domainName};expires=${exp.toUTCString()}`
  },
  changeNewDate (ts, type) {
    const dateStr = new Date(ts - 0)
    const y = dateStr.getFullYear()
    let mon = dateStr.getMonth() + 1
    mon = mon < 10 ? `0${mon}` : mon
    let d = dateStr.getDate()
    d = d < 10 ? `0${d}` : d
    if (type) {
      return `${y}/${mon}/${d}`
    }
    return `${y}-${mon}-${d}`
  },
  handleTimeText (data, DayWeekList) {
    if (!data) return
    let front = ''
    let behind = ''
    if (data.customFlag == 31) {
      front = data.cycleT > 1 ? i18n.t('lang.EveryFewDays', { number: data.cycleT }) : i18n.t('lang.Daily')
    } else if (data.customFlag == 32) {
      const WeekList = data.dayOfWeek.split(',').sort()
      let weeks = ''
      WeekList.forEach((item, index) => {
        const week = DayWeekList[1][Number(item) - 1]
        if (index) {
          weeks += `, ${i18n.t(`lang.${week}`)}`
        } else {
          weeks = i18n.t(`lang.${week}`)
        }
      })
      front = data.cycleT > 1 ? i18n.t('lang.EveryFewWeeks', { number: data.cycleT, week: weeks }) : i18n.t('lang.AWeekAFew', { week: weeks })
    } else if (data.customFlag == 33) {
      if (data.monthType == 10) {
        const SeveralFew = {
          day: Number(data.dayOfMonth)
        }
        front = data.cycleT > 1 ? i18n.t('lang.EveryMonthlyDay', { number: data.cycleT, day: SeveralFew.day }) : i18n.t('lang.MonthlyDay', { day: SeveralFew.day })
      } else if (data.monthType == 20) {
        const month = data.dayOfMonth.split(',')
        const SeveralFew = {
          number: Number(month[0]),
          week: Number(month[1])
        }
        const FirstWeek = {
          first: DayWeekList[0][SeveralFew.number - 1],
          week: DayWeekList[1][SeveralFew.week - 1]
        }
        front = data.cycleT > 1 ? i18n.t('lang.EveryMonthlyFirstWeeks', { number: data.cycleT, first: i18n.t(`lang.${FirstWeek.first}`), week: i18n.t(`lang.${FirstWeek.week}`) }) : i18n.t('lang.MonthlyFirstWeeks', { first: i18n.t(`lang.${FirstWeek.first}`), week: i18n.t(`lang.${FirstWeek.week}`) })
      }
    }
    if (data.stopFlag == 10) {
      const time = this.changeNewDate(data.repeatStopTime, 1)
      behind = i18n.t('lang.UntilTime', { time: time })
    } else if (data.stopFlag == 20) {
      behind = i18n.t('lang.HowTimes', { times: data.repeatCount })
    }
    return front + behind
  },
  isJSON (str) {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  },
  openConfigT (obj) {
    if (!obj.peerid) return
    const strWindowFeatures = 'height=600, width=1027, top=200, left=300, toolbar=no, menubar=no, titlebar=no, scrollbars=no, resizable=no,channelmode=no, location=no, status=no'
    const url = `http://${localStorage.getItem('newCCUrl')}/configT.html`
    if (obj.showIP) {
      window.open(
        `${url}#?tsession=${localStorage.getItem('NewCCsession')}&peerid=${this.encryption(obj.peerid)}&bindip=${obj.ip && obj.ip == '' ? '' : window.btoa(obj.ip)}&flag=${obj.flag}`,
        '_blank',
        strWindowFeatures
      )
    } else {
      window.open(
        `${url}?tsession=${localStorage.getItem('NewCCsession')}&peerid=${this.encryption(obj.peerid)}`,
        '_blank',
        strWindowFeatures
      )
    }
  }
}

export default Tool
