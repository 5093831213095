/*
 *  lang=>对应的中英文翻译 *
 *  type=>提示类型 *
 *  errorCode=>错误代码 * => 例如 81000005
 *
 */

export const toTips = {
  // Start Stop的时候的提示
  81000005: 'lang.timeOut',
  81100207: 'lang.sourceNotHave',
  81100101: 'lang.ReceiverNotExist',
  81100102: 'lang.receiverOffline',
  81100103: 'lang.sourceNotExist',
  81100105: 'lang.Sourceliving',
  81100106: 'lang.unknow',
  81100107: 'lang.PeerNotFound',
  81100108: 'lang.ReceiverDeviceOnLine',
  81100109: 'lang.PeerIncorrectStatus',
  81100110: 'lang.SourceNotBelongToUser',
  81100111: 'lang.ReceiverNotBelongToUser',
  81000014: 'lang.UserRoleNoFeature',
  81100201: 'lang.ReceiverNotExist',
  81100202: 'lang.receiverOffline',
  81100203: 'lang.receiverOnline',
  81100204: 'lang.unknow',
  81100205: 'lang.PeerNotFound',
  81100206: 'lang.GivenPeerNotSelected',
  81100112: 'lang.isBooking',
  81100015: 'lang.currentRLiveSourceNotBelongToUser',
  81000004: 'lang.receiverNotConnect',
  81100114: 'lang.i18n_startLiveFailed',
  // Start Stop的时候的提示 结束
  81000902: 'lang.bookmarkNameTips',
  81000801: 'lang.connotDelete',
  81000701: 'lang.useExist',
  '0x80100005': 'lang.GivenPeerNotSelected',
  // setting
  81400101: 'lang.companyExist',
  81000804: 'lang.extLiving',
  81100602: 'lang.noBindUser',
  81100501: 'lang.bindFail',
  81100502: 'lang.bindedDevice',
  81400102: 'lang.emailNotExist',
  81400103: 'lang.noAccessApp',
  81100601: 'lang.removeAllFail',
  81100701: 'lang.removeFail',
  81100403: 'lang.noPairR',
  81001902: 'lang.newDeviceNotExit',
  81001903: 'lang.theSameDevice',
  // 81002401: 'lang.useExist',
  81002701: 'lang.connectTpcFail',
  81002702: 'lang.connectTpcFail',
  81002801: 'lang.connectInstanceFail',
  81002802: 'lang.getRegionFail',
  81002803: 'lang.createInsFail',
  81002804: 'lang.shutdownInsFail',
  81002805: 'lang.shutdownInsFail',
  81002901: 'lang.userInsnull',
  81002902: 'lang.userInsreadyCLose',
  81000601: 'lang.userExist',
  81003101: 'lang.userNotExist',
  81003102: 'lang.emailExistInCC',
  81003103: 'lang.userNotExist',
  81003104: 'lang.emailExistInCC',
  81003105: 'lang.emailExistInDB',
  81003106: 'lang.USUpdateFailed',
  81003107: 'lang.DBUpdateFailed',
  81002401: 'lang.getSessionFailed',

  // advance control
  81000101: 'lang.nameExist',
  // timelock
  81100504: 'lang.synchronizing',
  81100505: 'lang.deviceSynchronizing',
  // token
  '0x000001': 'lang.notSpecialChar',
  '0x80100015': 'lang.existName',
  81000002: 'lang.GivenPeerNotSelected',
  81100104: 'lang.DeviceOffLine',
  // common
  '0x0': 'lang.success',
  /*
   * booking
   * booking.addEvent
   */
  80601701: 'lang.invalidSourcePeerId',
  80601702: 'lang.invalidDestPeerId',
  80601703: 'lang.sourceTypeIsNull',
  80601704: 'lang.invalidStartTime',
  80601705: 'lang.invalidEndTime',
  80601706: 'lang.endTimeMoreStartTime',
  80601707: 'lang.startTimeMoreCurTime',
  80601708: 'lang.sourceSetDelay',
  80601709: 'lang.delayInputRange',
  80601710: 'lang.sourceSetBitrate',
  80601711: 'lang.bitrateInput',
  80601712: 'lang.extMustSetUrl',
  80600702: 'lang.bookingRTimeconflict',
  80600703: 'lang.bookingTTimeconflict',
  80602311: 'lang.startTimeLessThanCurTime',
  80602313: 'lang.endTimeMoreStartTime',
  80602314: 'lang.delayRang',

  // booking.delete
  80600705: 'lang.deleteEventFail',
  80600704: 'lang.eventIdEmpty',

  // booking.update
  80600707: 'lang.eventNotexist',
  80600708: 'lang.eventNotEdit',
  80600709: 'lang.matrixEventCannotModify',
  80600706: 'lang.notEditEvent',
  80600710: 'lang.msgpipeConnectError',

  // token.editDeviceName
  '0x5': 'lang.faildCallTpcModifyName',

  // scte
  80601804: 'lang.nameIsExist',
  80601806: 'lang.sctePrerollTime',

  // 定制化CC
  81002602: 'lang.groupExist',
  81002601: 'lang.groupExist',
  81002606: 'lang.defaultNotDelete',
  81002605: 'lang.existDeviceGroup',
  80602302: 'lang.eventNoExist',
  80602305: 'lang.sourceNoExist',
  80602306: 'lang.rEmpty',
  80602308: 'lang.groupEmpty',
  80600720: 'lang.eventNotEdit',
  80602321: 'lang.bookingRTimeconflict',
  80602322: 'lang.bookingTTimeconflict',
  80602323: 'lang.bookingTTimeconflict',
  80602324: 'lang.serverFailed',
  80602325: 'lang.serverFailed',
  80602001: 'lang.noIdleChannel',
  80600111: 'lang.deleteEventFail',
  80602401: 'lang.groupNotExist',
  81002603: 'lang.groupNotExist',
  81002604: 'lang.userNoGroup',
  81101201: 'lang.groupNotExist',
  81101301: 'lang.noDevice',
  81101302: 'lang.ReceiverNotExist',
  81101303: 'lang.sourceNoExist',
  81101304: 'lang.deviceNotT',
  81101305: 'lang.rIsNotLiving',
  81101309: 'lang.registFailed',
  81101401: 'lang.noIdleChannel',
  81101402: 'lang.noIdleChannel',
  81101403: 'lang.noIdleChannel',
  81101404: 'lang.noIdleChannel',
  81101405: 'lang.sourceNoExist',
  81101406: 'lang.noIdleChannel',
  81101712: 'lang.accountSharing',
  81101713: 'lang.accountNoSharing',
  81101701: 'lang.systemError',
  81101704: 'lang.systemError',
  81101705: 'lang.systemError',
  81101707: 'lang.pushFailed',
  81101706: 'lang.pushFailed',
  81101708: 'lang.stopPushFailed',
  81101709: 'lang.stopPushFailed',

  // source control
  81101502: 'lang.channelOccupied',

  // alert
  80600700: 'lang.errorCodeFormartError',

  82600107: 'lang.overMax',
  82600106: 'lang.nameIsExist',
  82600108: 'lang.overDevice',
  20100010: 'lang.nameExit',
  // 80600700: 'lang.nameExit',
  81003303: 'lang.roleNotExist',

  80060001: 'lang.alreadyShare',

  // slug
  80600112: 'lang.updateSlugFailed'
}
export default toTips
