/*
 * 用来定义常用的一些常量
 * 使用时 直接引入对应名称
 */

// 按钮状态
export const BtnStatus = {
  0: 'gray',
  1: 'green',
  2: 'red'
}
export const BtnName = {
  0: 'Live',
  1: 'Live',
  2: 'Stop'
}

export const nameColor = {
  0: 'grayColor',
  1: 'whiteColor',
  2: 'redColor'
}
// 账号类型
export const SocialType = [
  {
    key: 'all',
    value: 'all',
    label: 'all',
    isChecked: true
  },
  {
    value: 'Facebook',
    key: 'F',
    label: 'Facebook',
    isChecked: true
  },
  {
    value: 'YouTube',
    key: 'Y',
    label: 'YouTube',
    isChecked: true
  },
  {
    value: 'YiLive',
    key: 'Z',
    label: 'YiLive',
    isChecked: true
  },
  {
    value: 'Twitter',
    key: 'Twitter',
    label: 'Twitter',
    isChecked: true
  },
  {
    value: 'Twitch',
    key: 'T',
    label: 'Twitch',
    isChecked: true
  },
  {
    value: 'Instagram',
    key: 'I',
    label: 'Instagram',
    isChecked: true
  },
  {
    value: 'KuaiShou',
    key: 'K',
    label: 'KuaiShou',
    isChecked: true
  },
  {
    value: 'Vimeo',
    key: 'Vimeo',
    label: 'Vimeo'
  },
  {
    value: 'Others',
    key: 'O',
    label: 'Others',
    isChecked: true
  }
]
// 设备状态 advanced control
export const StatusSelect = [
  {
    value: 'living',
    label: 'live',
    isChecked: true,
    color: 'red'
  },
  {
    value: 'online',
    label: 'online',
    isChecked: true,
    color: 'green'
  },
  {
    value: 'offline',
    label: 'offline',
    isChecked: true,
    color: 'gray'
  }
]
// 设备状态 source control
export const StatusSourceSelect = [
  {
    value: 'living',
    label: 'live',
    isChecked: true,
    color: 'red'
  },
  {
    value: 'noCamera',
    label: 'noSource',
    isChecked: true
  },
  {
    value: 'online',
    label: 'online',
    isChecked: true,
    color: 'green'
  },
  {
    value: 'offline',
    label: 'offline',
    isChecked: true,
    color: 'gray'
  }
]

// sourceControl设备类型
export const SourceTypeSelect = [
  {
    value: 'all',
    label: 'all',
    isChecked: true
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: true
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: true
  },
  {
    value: 'token',
    label: 'Token',
    isChecked: true
  },
  /*
   * {
   *     value: 'globalGrid',
   *     label: 'GlobalGrid',
   *     isChecked: true
   * },
   */
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: true
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true
  },
  {
    value: 'localSdi',
    label: 'Local-SDI',
    isChecked: true
  }
]
// 设备类型
export const TypeSelect = [
  {
    value: 'all',
    label: 'all',
    isChecked: true
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: true
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: true
  },
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: true
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true
  },
  {
    value: 'ext',
    label: 'Ext',
    isChecked: true
  },
  {
    value: 'token',
    label: 'TToken',
    isChecked: false
  },
  {
    value: 'gridViaToken',
    label: 'GridViaToken',
    isChecked: true
  },
  {
    value: 'globalGrid',
    label: 'GlobalGrid',
    isChecked: true
  },
  {
    value: 'localSdi',
    label: 'Local SDI',
    isChecked: true
  }
]

// 设备类型
export const AdvancedTypeSelect = [
  {
    value: 'all',
    label: 'all',
    isChecked: true
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: true
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: true
  },
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: true
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true
  },
  {
    value: 'ext',
    label: 'Ext',
    isChecked: true
  },
  /*
   * {
   *     value: 'token',
   *     label: 'TToken',
   *     isChecked: false
   * },
   * {
   *     value: 'gridViaToken',
   *     label: 'GridViaToken',
   *     isChecked: true
   * },
   * {
   *     value: 'globalGrid',
   *     label: 'GlobalGrid',
   *     isChecked: true
   * },
   */
  {
    value: 'glink',
    label: 'GLink',
    isChecked: true
  },
  {
    value: 'rtil',
    label: 'RTIL',
    isChecked: true
  },
  {
    value: 'localSdi',
    label: 'Local-SDI',
    isChecked: true
  }
]

// sourcelist 中type对应的websoket数据中的key
export const SourceTypeInKey = {
  Peerlist: 'PackPeerManager',
  GridPeerList: 'GridPeerManager', // Grid-Pack Grid-SDI
  LocalSDIList: 'LocalSDIPeerManager',
  LocalPeerList: 'LocalExternalPeerManager',
  RemotePeerList: 'RemoteExternalPeerManager',
  GLinkList: 'GLinkPeerManager',
  RtilList: 'AgoraPeerManager'
}

export const myResouceMapIcon = {
  onlineImg: require('@/assets/img/map/onlineMarker.png'),
  sourceImg: require('@/assets/img/map/liveMarker.png'),
  offlineImg: require('@/assets/img/map/offlineMarker.png'),
  receiverImg: require('@/assets/img/map/receiver_live.png'),
  logoImg: require('@/assets/img/logo_thumbnail.png'),
  notScale: require('@/assets/img/map/notScale.png'),
  notInScale: require('@/assets/img/map/notInScale.png')
}

export const DefaultImgByStatus = {
  T: {
    type: 'TVUPack',
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png'),
    3: require('@/assets/img/null.png'),
    4: require('@/assets/img/logot.png')
  },
  X: {
    type: 'TVUGrid',
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png'),
    3: require('@/assets/img/null.png'),
    4: require('@/assets/img/logot.png'),
    1000: require('@/assets/img/logot.png'),
    1001: require('@/assets/img/unknown.png')
  },
  Ext: {
    type: 'Ext',
    // img: require('@/assets/img/ext.jpg'),
    img: require('@/assets/img/External.png')
  },
  YouTube: {
    type: 'YouTube',
    img: require('@/assets/img/YouTube_2.png')
    // img: require('@/assets/img/youtube.jpg'),
  },
  SRT: {
    type: 'SRT',
    img: require('@/assets/img/SRT.png')
    // img: require('@/assets/img/youtube.jpg'),
  },
  TVUGrid: {
    type: 'TVUGrid',
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png'),
    3: require('@/assets/img/null.png')
  },
  LocalSDI: {
    type: 'LocalSDI',
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png')
  },
  SMPTE2022: {
    type: 'SMPTE2022',
    img: require('@/assets/img/SMPTE.png')
  },
  FILE: {
    type: 'FILE',
    // img: require('@/assets/img/ext.jpg'),
    img: require('@/assets/img/External.png')
  },
  GLink: {
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png'),
    3: require('@/assets/img/null.png'),
    4: require('@/assets/img/logot.png')
  },
  Rtil: {
    0: require('@/assets/img/offlinePng.png'),
    1: require('@/assets/img/null.png'),
    3: require('@/assets/img/null.png'),
    4: require('@/assets/img/logot.png')
  }
}

// 筛选排序 by online off name
export const SelectBySort = {
  onlineFirst: {
    label: 'Online',
    value: 'onlineFirst',
    data: true
  },
  liveFirst: {
    label: 'Live',
    value: 'liveFirst',
    data: true
  },
  nameFirst: {
    label: 'Name',
    value: 'nameFirst',
    data: true
  }
}
export const SelectBySorts = [
  {
    label: 'Online',
    value: 'onlineFirst',
    data: true
  },
  {
    label: 'Live',
    value: 'liveFirst',
    data: true
  },
  {
    label: 'Name',
    value: 'nameFirst',
    data: true
  }
]

// settings的下拉菜单
export const Settings = [
  {
    name: 'lang.BasicSetting',
    key: 'base'
  },
  {
    name: 'lang.General',
    key: 'general'
  },
  {
    name: 'lang.FeatureControl',
    key: 'feature'
  },
  {
    name: 'lang.FileBrowser',
    key: 'fileBrowser'
  },
  {
    name: 'lang.GLinkEncoder',
    key: 'gLinkEncoder'
  },
  {
    name: 'lang.GridEncoder',
    key: 'gridEncoder'
  },
  {
    name: 'lang.Transcriber',
    key: 'smartCaption'
  },
  {
    name: 'lang.SDIOutput',
    key: 'sdi'
  },
  {
    name: 'lang.VisionTag',
    key: 'visionTag'
  },
  {
    name: 'lang.AutoRecord',
    key: 'autoRecord'
  },
  {
    name: 'lang.IPStreamOut',
    key: 'ipStreamOut'
  },
  {
    name: 'lang.passThroughStream',
    key: 'Passthrough'
  },
  {
    name: 'lang.remoteFileStorage',
    key: 'FTPUpload'
  },
  {
    name: 'lang.AutoDownload',
    key: 'autoDownload'
  },
  {
    name: 'lang.ProgressiveDownload',
    key: 'progressDownload'
  },
  {
    name: 'lang.localAllPackPosition',
    key: 'localAllPack'
  },
  {
    name: 'lang.SetLocation',
    key: 'location'
  },
  {
    name: 'lang.IPandPortMapping',
    key: 'ipAndPort'
  },
  {
    name: 'lang.SetMedia',
    key: 'media'
  },
  {
    name: 'lang.Connectivity',
    key: 'Connectivity'
  },
  {
    name: 'lang.voipStart',
    key: 'VoipStart'
  },
  {
    name: 'lang.tokenService',
    key: 'tokenService'
  },
  {
    name: 'lang.VLANSetting',
    key: 'VLANSetting'
  },
  {
    name: 'lang.RestartService',
    key: 'Restart'
  },
  {
    name: 'lang.Reboot',
    key: 'Reboot'
  }
]

// record模块里面的icon图标
export const IconList = {
  0: require('@/assets/img/downloading.png'),
  1: require('@/assets/img/paused.png'),
  2: require('@/assets/img/task.png'),
  3: require('@/assets/img/downloading.png'),
  4: require('@/assets/img/transcoding.png'),
  5: require('@/assets/img/exclam_ico.png'),
  6: require('@/assets/img/Record_folder.png'),
  7: require('@/assets/img/autosync_ico.png')
}

export const VoipImage = {
  0: require('@/assets/img/calling.png'),
  1: require('@/assets/img/connecting.png'),
  2: require('@/assets/img/endCall.png')
}

// mysource 状态
export const MySourceStatus = [
  {
    value: 'all',
    label: 'all',
    isChecked: false
  },
  {
    value: 'online',
    label: 'available',
    isChecked: false,
    color: 'green'
  },
  {
    value: 'noCamera',
    label: 'noSource',
    isChecked: false
  },
  {
    value: 'living',
    label: 'live',
    isChecked: false,
    color: 'red'
  },
  {
    value: 'offline',
    label: 'offline',
    isChecked: false,
    color: 'gray'
  }
]

export const MySReceiverStatus = [
  {
    value: 'all',
    label: 'all',
    isChecked: false
  },
  {
    value: 'online',
    label: 'available',
    isChecked: false,
    color: 'green'
  },
  {
    value: 'living',
    label: 'live',
    isChecked: false,
    color: 'red'
  },
  {
    value: 'offline',
    label: 'offline',
    isChecked: false,
    color: 'gray'
  },
  {
    value: 'sort',
    label: 'dynamicsorting',
    isChecked: false
  }
]

// mysource 类型
export const MySourceType = [
  {
    value: 'all',
    label: 'all',
    isChecked: false,
    icon: '&#xe765;'
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: false,
    icon: '&#xe641;'
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: false,
    icon: '&#xe766;'
  },
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: false,
    icon: '&#xe763;'
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true,
    icon: '&#xe762;'
  },
  {
    value: 'ext',
    label: 'Ext',
    isChecked: false,
    icon: '&#xe725;'
  }
  /*
   * {
   *     value: 'gridViaToken',
   *     label: 'viaToken',
   *     isChecked: false
   * },
   * {
   *     value: 'globalGrid',
   *     label: 'globalGrid',
   *     isChecked: false
   * },
   * {
   *     value: 'token',
   *     label: 'TToken',
   *     isChecked: false
   * }
   */
]

// 设备类型
export const SourceType = [
  {
    label: 'all',
    value: 'all'
  },
  {
    label: 'SMPTE2022',
    value: 'SMPTE2022'
  },
  {
    label: 'Ext',
    value: 'Ext'
  },
  {
    label: 'YouTube',
    value: 'YouTube'
  }
]

// mysource 类型
export const AccountDeviceType = [
  {
    value: 'all',
    label: 'All',
    isChecked: true
  },
  {
    value: 'receiver',
    label: 'Receiver',
    isChecked: true
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: true
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: true
  },
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: true
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true
  },
  {
    value: 'token',
    label: 'TToken',
    isChecked: true
  }
]

// 用户角色
export const RoleType = {
  2: 'support',
  3: 'admin',
  4: 'user'
}
export const ManageRoleType = {
  2: 'supportManager',
  1: 'systemAdmin',
  3: 'admin',
  4: 'user'
}

// setting/device
export const DeviceType = [
  {
    value: 'all',
    label: 'all',
    isChecked: true
  },
  {
    value: 'pack',
    label: 'TVUPack',
    isChecked: true
  },
  {
    value: 'anywhere',
    label: 'TVUAnywhere',
    isChecked: true
  },
  {
    value: 'gridPack',
    label: 'Grid-Pack',
    isChecked: true
  },
  {
    value: 'gridSdi',
    label: 'Grid-SDI',
    isChecked: true
  },
  {
    value: 'receiver',
    label: 'receiver',
    isChecked: true
  },
  {
    value: 'token',
    label: 'TToken',
    isChecked: true
  }
]

export const DeviceAddress = [
  {
    label: 'all',
    value: 'all',
    isChecked: true
  },
  {
    label: 'automatic',
    value: 'automatic',
    isChecked: true
  },
  {
    label: 'manual',
    value: 'manual',
    isChecked: true
  },
  {
    label: 'noPosition',
    value: 'noPosition',
    isChecked: true
  }
]

export const BitrateList = [
  {
    label: 'Auto',
    value: 0
  },
  {
    label: '1.2Mb/s',
    value: 1.2
  },
  {
    label: '2.4Mb/s',
    value: 2.4
  },
  {
    label: '6Mb/s',
    value: 6
  },
  {
    label: '12Mb/s',
    value: 12
  },
  {
    label: '24Mb/s',
    value: 24
  },
  {
    label: '30Mb/s',
    value: 30
  },
  {
    label: '50Mb/s',
    value: 50
  },
  {
    label: '100Mb/s',
    value: 100
  },
  {
    label: '120Mb/s',
    value: 120
  },
  {
    label: '150Mb/s',
    value: 150
  },
  {
    label: '180Mb/s',
    value: 180
  },
  {
    label: '200Mb/s',
    value: 200
  },
  {
    label: '240Mb/s',
    value: 240
  }
]

export const speedList = [
  {
    label: 'Auto',
    value: 'Auto',
    key: 'zero'
  },
  {
    label: '1.2Mb/s',
    value: '1.2Mb/s',
    key: 'first'
  },
  {
    label: '2.4Mb/s',
    value: '2.4Mb/s',
    key: 'second'
  },
  {
    label: '6Mb/s',
    value: '6Mb/s',
    key: 'three'
  },
  {
    label: '12Mb/s',
    value: '12Mb/s',
    key: 'four'
  },
  {
    label: '24Mb/s',
    value: '24Mb/s',
    key: 'five'
  },
  {
    label: '30Mb/s',
    value: '30Mb/s',
    key: 'six'
  },
  {
    label: '50Mb/s',
    value: '50Mb/s',
    key: 'seven'
  },
  {
    label: '100Mb/s',
    value: '100Mb/s',
    key: 'eight'
  },
  {
    label: '120Mb/s',
    value: '120Mb/s',
    key: 'nine'
  },
  {
    label: '150Mb/s',
    value: '150Mb/s',
    key: 'ten'
  },
  {
    label: '180Mb/s',
    value: '180Mb/s',
    key: 'eleven'
  },
  {
    label: '200Mb/s',
    value: '200Mb/s',
    key: 'twelve'
  },
  {
    label: '240Mb/s',
    value: '240Mb/s',
    key: 'thirteen'
  }
]
export const ThumnailImg = {
  0: require('@/assets/img/offline.png'),
  1: require('@/assets/img/null.png'),
  2: require('@/assets/img/logot.png'),
  3: require('@/assets/img/null.png'),
  Ext: require('@/assets/img/v3_logoExt.jpg'),
  YouTube: require('@/assets/img/YouTube_2.png'),
  SMPTE2022: require('@/assets/img/SMPTE.png'),
  SRT: require('@/assets/img/SRT.png'),
  1000: require('@/assets/img/offline_rs.png'),
  1001: require('@/assets/img/unknown.png')
}

export const formatList = [
  { value: 'NTSC', label: 'NTSC' },
  { value: 'PAL', label: 'PAL' },
  { value: '720P50', label: '720P50' },
  { value: '720P5994', label: '720P5994' },
  { value: '720P60', label: '720P60' },
  { value: '1080I50', label: '1080I50' },
  { value: '1080I5994', label: '1080I5994' },
  { value: '1080P25', label: '1080P25' },
  { value: '1080P30', label: '1080P30' },
  { value: '1080P2997', label: '1080P2997' },
  { value: '1080P50', label: '1080P50' },
  { value: '1080P5994', label: '1080P5994' },
  { value: '1080P60', label: '1080P60' },
  { value: '4KP25', label: '4KP25' },
  { value: '4KP2997', label: '4KP2997' },
  { value: '4KP30', label: '4KP30' },
  { value: '4KP60', label: '4KP60' }
]

export const cloudRStatus = [
  {
    value: 'all',
    label: 'all',
    isChecked: true,
    color: 'green'
  },
  {
    value: 'Scheduledfeature',
    label: 'ScheduleFeature',
    isChecked: true,
    color: 'green'
  },
  {
    value: 'Running',
    label: 'running',
    isChecked: true,
    color: 'green'
  },
  {
    value: 'Closed',
    label: 'ClosedCloudR',
    isChecked: true,
    color: 'green'
  }
]

export const WeekDayList = [
  ['first', 'second', 'third', 'fourth', 'fifth'],
  [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
]
export const ShutTestTime = [{
  label: '1 Day',
  value: 1
}, {
  label: '2 Days',
  value: 2
}, {
  label: '3 Days',
  value: 3
}, {
  label: '4 Days',
  value: 4
}, {
  label: '5 Days',
  value: 5
}, {
  label: '6 Days',
  value: 6
}, {
  label: '7 Days',
  value: 7
}]
export const RepeatTypeList = [
  {
    label: 'DoesNotRepeat',
    value: 0,
    switch: true
  },
  {
    label: 'Daily',
    value: 10,
    switch: true
  },
  {
    label: 'EveryWeekday',
    value: 20,
    switch: true
  },
  {
    label: 'EveryWeekend',
    value: 21,
    switch: true
  }
  // {
  //   label: 'Custom',
  //   value: 30,
  //   switch: true
  // }
]
export const UsageTypes = [
  {
    label: 'Buy',
    value: 'Buy'
  }, {
    label: 'Test',
    value: 'Test'
  }
]

export const RHardware = {
  50001: {
    Platform: 'TX3200',
    Revision: 'V1',
    CPUName: 'i7-3770',
    MotherboardName: 'DH61AG',
    Memory: '8GB'
  },
  50002: {
    Platform: 'TX3200',
    Revision: 'V2',
    CPUName: 'i7-3770',
    MotherboardName: 'GA-B75TN',
    Memory: '8GB'
  },
  50003: {
    Platform: 'TX3200',
    Revision: 'V3',
    CPUName: 'i7-4790',
    MotherboardName: 'MSQ87TN',
    Memory: '8GB',
    Remark: ''
  },
  50004: {
    Platform: 'TX3200',
    Revision: 'V4',
    CPUName: 'i7-6700',
    MotherboardName: 'H110TN',
    Memory: '8GB',
    Remark: ''
  },
  50101: {
    Platform: 'VS3100',
    Revision: 'V1',
    CPUName: 'i7-6700',
    MotherboardName: 'H110M-STX',
    Memory: '8GB',
    Remark: ''
  },
  50111: {
    Platform: 'VS3400',
    Revision: 'V1',
    CPUName: 'i3-10100',
    MotherboardName: 'Z490M-ITX/ac',
    Memory: '8GB',
    Remark: 'Short-lived ghost (due to the motherboard)'
  },
  50121: {
    Platform: 'VS3500',
    Revision: 'V1',
    CPUName: 'E3-1585 v5',
    MotherboardName: 'C236 WSI4',
    Memory: '8GB',
    Remark: 'Added on 12/06/2023'
  },
  50122: {
    Platform: 'VS3500',
    Revision: 'V2',
    CPUName: 'i7-10700',
    MotherboardName: 'Z490M-ITX/ac',
    Memory: '16GB',
    Remark: 'Short-lived ghost (due to the motherboard)'
  },
  50123: {
    Platform: 'VS3500',
    Revision: 'V3',
    CPUName: 'i7-10700',
    MotherboardName: 'Z590M-ITX/ax',
    Memory: '16GB',
    Remark: ''
  },
  50124: {
    Platform: 'VS3500',
    Revision: 'V4',
    CPUName: 'i7-11700',
    MotherboardName: 'Z590M-ITX/ax',
    Memory: '16GB',
    Remark: 'Not mass produced (due to the CPU Jan 2022)'
  },
  50125: {
    Platform: 'VS3500',
    Revision: 'V5',
    CPUName: 'i7-11700',
    MotherboardName: 'H510M-ITX/ac',
    Memory: '16GB',
    Remark: ''
  },
  50126: {
    Platform: 'VS3500',
    Revision: 'V6',
    CPUName: 'i9-12900',
    MotherboardName: 'i9-12900H',
    Memory: '16GB',
    Remark: 'G-Link 4K'
  },
  50127: {
    Platform: 'VS3500',
    Revision: 'V7',
    CPUName: 'i7-12700',
    MotherboardName: 'IMB-1231',
    Memory: '16GB',
    Remark: 'Since 2023/08'
  },
  50131: {
    Platform: 'VS3600',
    Revision: 'V1',
    CPUName: 'i9-9900K',
    MotherboardName: 'Z390M-ITX/ac',
    Memory: '32GB',
    Remark: 'Short-lived ghost (due to the CPU)'
  },
  50132: {
    Platform: 'VS3600',
    Revision: 'V2',
    CPUName: 'i7-10700',
    MotherboardName: 'Z490M-ITX/ac',
    Memory: '32GB',
    Remark: 'Short-lived ghost (due to the motherboard)'
  },
  50133: {
    Platform: 'VS3600',
    Revision: 'V3',
    CPUName: 'i7-10700',
    MotherboardName: 'Z590M-ITX/ax',
    Memory: '32GB',
    Remark: ''
  },
  50134: {
    Platform: 'VS3600',
    Revision: 'V4',
    CPUName: 'i7-11700',
    MotherboardName: 'Z590M-ITX/ax',
    Memory: '32GB',
    Remark: 'Not mass produced (due to the CPU Jan 2022)'
  },
  50135: {
    Platform: 'VS3600',
    Revision: 'V5',
    CPUName: 'i7-10700',
    MotherboardName: 'H510M-ITX/ac',
    Memory: '32GB',
    Remark: ''
  },
  50136: {
    Platform: 'VS3600',
    Revision: 'V6',
    CPUName: 'i9-12900',
    MotherboardName: 'i9-12900H',
    Memory: '32GB',
    Remark: 'G-Link 4K'
  },
  50137: {
    Platform: 'VS3600',
    Revision: 'V7',
    CPUName: 'i7-12700',
    MotherboardName: 'IMB-1231',
    Memory: '32GB',
    Remark: 'Since 2023/08'
  }
}
export default {
  BtnStatus,
  StatusSelect,
  TypeSelect,
  AdvancedTypeSelect,
  BtnName,
  VoipImage,
  SourceTypeInKey,
  myResouceMapIcon,
  DefaultImgByStatus,
  SelectBySort,
  Settings,
  IconList,
  MySourceStatus,
  MySourceType,
  SelectBySorts,
  SourceType,
  RoleType,
  ManageRoleType,
  AccountDeviceType,
  DeviceType,
  DeviceAddress,
  BitrateList,
  ThumnailImg,
  speedList,
  formatList,
  cloudRStatus,
  WeekDayList,
  ShutTestTime,
  RepeatTypeList,
  UsageTypes,
  RHardware
}
