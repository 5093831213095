import axios from 'axios'
import Qs from 'qs'
import Tool from '@/assets/js/utils.js'
import Bus from '@/assets/js/vueBus'
import { Message } from 'element-ui'
// http request 拦截器

// 设置默认超时时间为15s， timeoutTips设置为true，会有请求超时的提示，默认不提示
axios.defaults.withCredentials = true
axios.defaults.timeout = 15000

// 用于连续多次点击时，取消之前的请求，之保留最后一次的请求状态
let pending = [] // 声明一个数组用于存储每个正在pending的状态请求
let cancelToken = axios.CancelToken
let removePending = (config, str) => {
  for (let i in pending) {
    if (pending[i].u === config.url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[i].f() // 执行取消操作
      pending.splice(i, 1)
    }
  }
}

let internetStatus = true // true => 代表在线  false => 代表离线

window.addEventListener('online', () => {
  internetStatus = true
})
window.addEventListener('offline', () => {
  internetStatus = false
})

axios.interceptors.request.use(
  config => {
    if (!internetStatus) {
      // 代表离线 给提示
      // utilVue.$message('') //新实例化的vue实例
      Message({
        message: i18n.t('lang.internetOffline'),
        type: 'error'
      })
      return false
    }
    if (config.isCancelToken) {
      removePending(config, 'request') // 在一个axios发送前执行一下取消操作
      config.cancelToken = new cancelToken(cancel => {
        // 这里的axios标识用请求地址&请求方式拼接的字符串
        pending.push({ u: config.url + '&' + config.method, f: cancel })
      })
    }

    if (
      (!config.url.includes('/tvucc-token') &&
        config.url.indexOf('/tvucc') > -1) ||
      config.url.indexOf('/command') > -1 ||
      config.url.indexOf('/tvucc-media') > -1
    ) {
      if (
        config.method === 'post' &&
        config.data.constructor !== FormData &&
        config.headers['Content-Type'] != 'application/json;charset=UTF-8' &&
        !config.contentType
      ) {
        if (
          !config.url.includes('/tvucc-user/userDevice/existUserBindDevice')
        ) {
          config.data = Qs.stringify(config.data)
        }
      }
      if (config.headers['Content-Type'] != 'application/json;charset=UTF-8') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }
      if (config.contentType) {
        /*
         * 如果需要修改content-type ，则在接口中传递 contentType的类型即可
         * this.axios.post('ccp/tvucc-user/userDevice/pageUserExt',this.params,{contentType:'application/json;charset=UTF-8'})
         * this.axios.get('/ccp/tvucc-user/user/page',{params:this.params,contentType:'application/json;charset=UTF-8'})
         */
        config.headers['Content-Type'] = config.contentType
      }
    }
    // token page
    if (
      config.url.includes('/tvucc-token') ||
      config.url.includes('/tvucc-user/userDevice/existUserBindDevice')
    ) {
      config.headers['Content-Type'] = 'application/json'
    }
    let urlArr = [
      '/event/getEventById',
      '/event/addEvent',
      '/event/update',
      '/event/delete',
      '/event/listEventByTR',
      '/device/editDeviceName'
    ]
    if (urlArr.includes(config.url)) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = Qs.stringify(config.data)
    }
    if (config.url == '/ccp/tvu-configT/configT/getUrl' && !config.params.rpid) {
      config.headers.Authorization = Tool.getCookie('TSID')
    } else if (config.url != '/ccp/tvu-configT/configT/getUrl') {
      config.headers.Authorization = localStorage.getItem('NewCCsession')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => {
    if (response.config && response.config.isCancelToken) {
      removePending(response.config, 'response') // 在一个axios响应后再执行一下取消操作，把已经完成的请求从pending中移除
    }
    if (response.data.errorCode === '80000302') {
      window.location.href = response.data.errorInfo
    }
    // if (response.data.errorCode === '81000001') {
    //   Bus.$emit('jumpLoginPage')
    // }
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 406:
        case 402:
        case 401:
          Bus.$emit('jumpLoginPage')
          break
        // 超时
        case 504:
          Message({
            message: i18n.t('lang.timeOut'),
            type: 'error'
          })
          break
      }
      // 服务器异常处理
      if ((error.response.status + '').substr(0, 1) == '5' && error.response.status != 504) {
        Message({
          message: i18n.t('lang.serverFailed'),
          type: 'error'
        })
      }
    }
    if (!error.response) {
      if (error.message && error.message.includes('timeout')) {
        // timeoutTips设置为true，会有请求超时的提示，默认不提示
        if (error.config.timeoutTips) {
          Message({ message: i18n.t('lang.timeOut'), type: 'error' })
        }
      }
      return Promise.reject(error)
    }
    return Promise.reject(error.response)
  }
)
export default axios
